<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col sm="12">
        <c-table
          :url="'AdminWebService/get_menus'"
          :fields="columns"
          :export="false"
          :edit_has="true"
          :edit_route="'system.menu.edit'"
          :edit_params="{ id: 0 }"
          hover
          bordered
          caption=""
        ></c-table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "Menu",
  data: function() {
    return {
      columns: [
        { label: "№", key: "index", class: "text-center" },
        { label: "Parent", key: "parent" },
        { label: "icon", key: "icon", class: "text-center" },
        { label: "Нэр", key: "name", sortable: true, sort_key: "name" },
        { label: "Линк", key: "action" },
        { label: "Код", key: "code", sortable: true, sort_key: "code" },
        {
          label: "Идэвхтэй эсэх",
          key: "isActive",
          sortable: true,
          sort_key: "is_active",
          class: "text-center",
        },
        { label: "Хандах эрх", key: "roleName" },
        { label: "Үйлдэл", key: "actions", buttons: [], class: "text-center" },
      ],
    };
  },
};
</script>
